import React, { useEffect, useRef, useState } from "react";

/** Libraries */
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";

/** Components */
import { fadeIn } from "../../Functions/GlobalAnimations";
import Buttons from "../../Components/Button/Buttons";

/** style */
import "../../../Assets/scss/MedicalCounsellingIndia/pages/home.scss";
import CommonContactButton from "../../Components/AnotherButton/CommonContactButton";
import MetaTags from "../../Components/MetaTags";
import { Link } from "react-router-dom";
import "./Bangalore.scss";

/** top college details */
const topCollegeDetails = [
  {
    name: "Top Education Consultants in Bangalore,",
    link: "/top-education-consultant-in-bangalore",
  },
  {
    name: "Top Education Consultants in Chennai,",
    link: "/top-education-consultant-in-chennai",
  },
  {
    name: " Top Education Consultants in Jamshedpur,",

    link: "/top-education-consultant-in-jamshedpur",
  },
  {
    name: " Top Education Consultants in Mumbai,",

    link: "/top-education-consultant-in-mumbai",
  },
  {
    name: " Top Education Consultants in Pune,",

    link: "/top-education-consultant-in-pune",
  },
  {
    name: " Top Education Consultants in uttar Pradash,",

    link: "/top-education-consultant-in-uttar-pradash",
  },
];

function Bangalore() {
  const [formLoading, setFormLoading] = useState(false);
  const collegeSectionRef = useRef(null);
  const location = useLocation();

  // const collegeTitle = collegeName
  // .split("-")
  // .map((curElem) => {
  //   if (exceptions.includes(curElem)) return curElem;
  //   return curElem.charAt(0).toUpperCase() + curElem.slice(1);
  // })
  // .join(" ");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    console.log(data);
    setFormLoading(true);
    toast.success("Your message has been sent successfully!", {
      theme: "colored",
    });

    setTimeout(() => {
      setFormLoading(false);
    }, 1000);
  };

  const handleKeyDown = (event) => {
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
    const isNumberKey = !isNaN(Number(event.key)) && event.code !== "Space";

    if (!allowedKeys.includes(event.code) && !isNumberKey) {
      event.preventDefault();
    }
  };

  /** scroll to college */

  useEffect(() => {
    if (
      location.search.includes("scrollToCollege=true") &&
      collegeSectionRef.current
    ) {
      collegeSectionRef.current.scrollIntoView({ behavior: "smooth" });
      console.log("hi");
    }
  }, [location.search]);

  return (
    <>
      <MetaTags
        title="Top Education Consultant in Bangalore"
        description="Looking for the Top Education Consultant in Bangalore? Get expert guidance on career counseling. Contact us at 9730858444 "
        keywords="Top Education Consultant in Bangalore, Best Education Consultant in Bangalore, Education Consultant in Bangalore"
      />

      {/* banner Parallax Scrolling End */}

      {/* instruction section start  */}
      <section className="bg-lightgray py-[150px] pb-[100px] lg:py-[50px]  md:py-[80px] md:pb-[120px]  sm:py-[100px]">
        <Container>
          {/* How to get Admission */}
          <div className="">
            <h1 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-none text-center">
              Top Education Consultant in Bangalore
            </h1>

            <m.div
              className="portfolio-boxed overflow-hidden"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              <div className="portfolio-image relative mx-auto">
                <img
                  className="w-full"
                  src={"/assets/img/webp/locations/bangalore.png"}
                  height={447}
                  width={550}
                  alt="portfolio-boxed"
                />
              </div>
            </m.div>
            <div>
              <div className="!text-[#616162] mt-4  ">
                <div className="mt-3">
                  Choosing the <b>Top Education Consultant in Bangalore</b> is
                  crucial for students seeking the right academic path.
                  <b>Counselling India</b> provides expert guidance for higher
                  education, helping students make informed decisions about
                  courses, universities, and career options. With a team of
                  experienced professionals, we assist in university
                  applications,
                  <b>Admisison Process in Management/ NRI Quota</b>,
                  <b>scholarship opportunities</b>, and entrance exam
                  preparation. Their personalized approach ensures students
                  receive tailored advice based on their interests and career
                  goals.
                </div>
                <div className="mt-3">
                  As the <b>Top Education Consultant in Bangalore</b>,
                  <b>Counselling India</b> stays updated with global education
                  trends, offering insights into the best institutions in India.
                  They help students select suitable study destinations,
                  considering
                  <b>Admission Process Management Quota</b>, <b>NRI Quota</b>,
                  and <b>Academic Quota</b> preferences. Their support extends
                  beyond admissions, providing mentorship for career growth and
                  skill development. Parents and students trust their expertise
                  in navigating complex admission processes, ensuring a smooth
                  and stress-free experience.
                </div>

                <div className="mt-3">
                  With a strong network of partner universities and successful
                  alumni, <b>Counselling India</b> enhances opportunities for
                  aspiring students. They simplify the decision-making process,
                  offering clarity on eligibility, course selection and college
                  selection. Choosing <b>Counselling India</b>, the{" "}
                  <b>Top Education Consultant in Bangalore</b>, guarantees
                  expert assistance for a well-planned and successful academic
                  journey.
                </div>
                <div className="mt-3">
                  <b>counselling India</b>, known as the best counsellor for
                  quality education in Bangalore, has also emerged as a hub for
                  quality education. With an increasing number of students
                  aspiring to study in top institutions in India and abroad, the
                  role of education consultants has become more crucial than
                  ever.
                  <b>Counselling India</b> best education consultant in
                  Bangalore provides professional guidance to students, helping
                  them choose the right career path, secure admissions in
                  reputed institutions, and achieve their academic and
                  professional dreams. In this article, we explore the top{" "}
                  <b>Counselling India</b>
                  education consultants in Bangalore and how they can assist
                  students in their educational journey.
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <CommonContactButton />
          </div>

          <div className="mt-5">
            <h1 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-normal">
              Why Choose an Counselling India?
            </h1>
            <div>
              <div className="!text-[#616162] mt-4  ">
                <div className="mt-3">
                  Choosing an education consultant ensures a smooth academic
                  journey with expert guidance on course selection, university
                  applications, and career planning. The{" "}
                  <b>Counselling India</b> <b>Top Education Consultant </b> in{" "}
                  <b>Bangalore</b> provides personalized assistance, helping
                  students navigate the complexities of admissions, counselling
                  procedures, and scholarships.
                </div>
                <div className="mt-3">
                  With years of experience, they offer insights into the best
                  institutions worldwide, aligning choices with career
                  aspirations. Their expertise reduces the risk of application
                  errors, increasing chances of acceptance into prestigious
                  universities. They assist in filling application form,
                  Admission Process, and documentation, ensuring a hassle-free
                  process. Parents and students benefit from their in-depth
                  knowledge of global education trends, making informed
                  decisions for a successful future.
                </div>

                <div className="mt-3">
                  <h2 className="font-semibold text-[#616162] text-[18px] leading-none m-0 ">
                    What do we do?
                  </h2>
                  <div className="mt-3">
                    <b>Counselling India</b> is dedicated to guiding students
                    and professionals towards a successful future through
                    comprehensive educational and career counseling services. We
                    specialize in helping individuals make informed decisions
                    about their academic and professional journeys, ensuring
                    they achieve their aspirations with confidence and clarity.
                  </div>
                </div>

                <div className="mt-3">
                  <b>Counselling India</b> is dedicated to guiding students and
                  professionals towards a successful future through
                  comprehensive educational and career counseling services. We
                  specialize in helping individuals make informed decisions
                  about their academic and professional journeys, ensuring they
                  achieve their aspirations with confidence and clarity.
                </div>

                <div className="mt-3">
                  Our educational counseling services are tailored to meet the
                  unique needs of students at various stages of their academic
                  life. From choosing the right courses and institutions to
                  navigating the complexities of the admission process, we
                  provide end-to-end support. We assist students in identifying
                  their strengths, interests, and career goals, enabling them to
                  select the most suitable educational paths.
                </div>
                <div className="mt-3">
                  In addition to educational counselling,{" "}
                  <b>Counselling India</b>
                  offers expert career counselling services. We help individuals
                  understand industry trends, skill requirements, and career
                  prospects. Our counselors provide personalized career plans,
                  guiding clients through career transitions, skill development,
                  and admission process search strategies. We empower
                  individuals to build rewarding and fulfilling careers aligned
                  with their passions.
                </div>

                <div className="mt-3">
                  Admission guidance is another cornerstone of our services. We
                  simplify the admission process for students seeking entry into
                  prestigious institutions in India and abroad. Our experienced
                  counsellors offer insights into eligibility criteria,
                  application procedures, and scholarship opportunities. We
                  ensure students are well-prepared to excel in competitive
                  environments and achieve their dreams.
                </div>
                <div className="mt-3">
                  At <b>Counselling India</b>, we are committed to providing
                  professional, personalized, and reliable counseling services.
                  Our goal is to help students and professionals make
                  well-informed decisions, unlock their potential, and build
                  successful futures. Whether you are a student exploring career
                  options or a professional seeking growth opportunities,
                  <b>Counselling</b>
                </div>
              </div>
            </div>
          </div>

          {/* general eligibility */}
          <div className="mt-[50px]">
            <h2 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-none">
              Services Offered by Education Consultants in Bangalore
            </h2>
            <div>
              <p className="!text-[#616162]">
                The <b>Top Education Consultant in Bangalore</b> offer a wide
                range of services, including:
              </p>
            </div>
            <div>
              <ol className="!text-[#616162] mt-4 ml-10  md:ml-5 list-decimal">
                <li>
                  <b> Career Counseling </b>: Assessing students' interests,
                  skills, and ambitions to guide them toward suitable career
                  paths
                </li>
                <li>
                  <b>Course and University Selection:</b> Helping students
                  choose the best institutions based on their preferences and
                  academic qualifications
                </li>
                <li>
                  <b>Admission Assistance:</b> Guiding students through the
                  application process, including documentation and entrance exam
                  preparation.
                </li>
                <li>
                  <b>Scholarship and Financial Aid Guidance:</b>
                  Helping students find and apply for scholarships and financial
                  aid programs.
                </li>
                <li>
                  <b>Post-Admission Support:</b>
                  Providing guidance on accommodation, travel, and settling in a
                  new country.
                </li>
              </ol>
            </div>
          </div>

          <div className="mt-[50px]">
            <h2 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-none">
              How to Choose the Right Education Consultant in Bangalor
            </h2>
            <div>
              <p className="!text-[#616162]">
                With several options available, selecting the right education
                consultant can be challenging. Here are some factors to consider
              </p>
            </div>
            <div>
              <ol className="!text-[#616162] mt-4 ml-10  md:ml-5 list-decimal">
                <li>
                  <b> Experience and Reputation: </b> Look for consultants with
                  a strong track record of success.
                </li>
                <li>
                  <b>Range of Services</b> Choose consultants who offer
                  end-to-end guidance, from career counseling to post-admission
                  support.
                </li>
                <li>
                  <b>Admission Assistance:</b> Guiding students through the
                  application process, including documentation and entrance exam
                  preparation.
                </li>
                <li>
                  <b>Student Testimonials:</b>
                  Check reviews and feedback from previous students.
                </li>
                <li>
                  <b>Transparency in Fees</b>
                  Ensure there are no hidden charges in their service packages.
                </li>
              </ol>
            </div>
          </div>

          <div className="mt-[50px]">
            <h2 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-none">
              The Future of Education Consulting in Bangalore
            </h2>
            <div>
              <p className="!text-[#616162]">
                With the increasing demand for quality education, the role of
                education consultants is evolving. The Counselling India{" "}
                <b>Top Education Consultant in Bangalore</b> are now
                incorporating technology-driven solutions like AI-based career
                counseling, virtual university tours, and online test
                preparation platforms. The future of education consulting in
                Bangalore looks promising as more students aspire to pursue
                higher education both in India and overseas.
              </p>
            </div>
            <p className="!text-[#616162]" mt-3>
              With the increasing demand for quality education, the role of
              education consultants is evolving. The Counselling India …Top
              Education Consultant in Bangalore are now incorporating
              technology-driven solutions like AI-based career counseling,
              virtual university tours, and online test preparation platforms.
              The future of education consulting in Bangalore looks promising as
              more students aspire to pursue higher education both in India and
              overseas.
            </p>{" "}
            <p className="!text-[#616162] mt-3">
              The future of <b>Education consulting in Bangalore </b> is
              evolving rapidly, with increasing demand for expert guidance in
              higher education. Counselling India, a leading name among
              education consultants, is set to play a crucial role in shaping
              students' academic journeys. With globalization and technological
              advancements, students now have access to diverse courses and
              universities worldwide. However, navigating complex admission
              processes, scholarships, and visa applications requires
              professional support.
            </p>{" "}
            <p className="!text-[#616162] mt-3">
              As one of the <b> Top Education Consultant in Bangalore, </b>{" "}
              Counselling India is adapting to these changes by offering
              personalized counseling, AI-driven career assessments, and
              up-to-date information on global education trends. The rise of
              online learning, hybrid education models, and skill-based courses
              is reshaping academic choices, making expert guidance even more
              essential. Parents and students rely on trusted consultants to
              simplify decision-making and enhance their chances of success in
              the admission procedure in top colleges in india.
            </p>{" "}
            <p className="!text-[#616162] mt-3">
              In the coming years, Counselling India aims to expand its
              services, integrating advanced technology and global partnerships
              to provide better opportunities for students. Their expertise in
              career planning, university selection,admission guidance and
              financial aid will continue to be invaluable. As education
              evolves, Counselling India remains committed to guiding students
              toward a successful and fulfilling academic future.
            </p>
          </div>

          <div className="mt-[50px]">
            <h2 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-none">
              Conclusion
            </h2>
            <div>
              <p className="!text-[#616162]">
                In conclusion, choosing the{" "}
                <b>Top Education Consultants in Bangalore, </b> like{" "}
                <b>Counselling India </b> , ensures a seamless academic journey
                with expert guidance at every step. Their in-depth knowledge of
                global education trends, university admissions, scholarships,
                and career planning helps students make informed decisions. With
                a student-centric approach, they provide personalized
                counseling, assisting in course selection, test preparation,
                visa processing, and financial aid opportunities.
              </p>
            </div>
            <p className="!text-[#616162]" mt-3>
              The expertise of <b>Counselling India </b> simplifies the complex
              admission process, reducing errors and increasing acceptance
              chances in prestigious institutions. Their strong network of
              partner universities and successful alumni creates better
              prospects for aspiring students. Parents and students benefit from
              their professional insights, ensuring a well-structured
              educational path that aligns with career goals.{" "}
            </p>{" "}
            <p className="!text-[#616162] mt-3">
              The future of <b>Education consulting in Bangalore </b> is
              evolving rapidly, with increasing demand for expert guidance in
              higher education. Counselling India, a leading name among
              education consultants, is set to play a crucial role in shaping
              students' academic journeys. With globalization and technological
              advancements, students now have access to diverse courses and
              universities worldwide. However, navigating complex admission
              processes, scholarships, and visa applications requires
              professional support.
            </p>{" "}
            <p className="!text-[#616162] mt-3">
              As one of the <b>Top Education Consultant in Bangalore </b>,
              Counselling India continues to guide students toward academic
              excellence and career success. Their commitment to providing
              accurate, updated, and relevant information makes them a trusted
              partner in education. With their support, students can confidently
              pursue their dreams, knowing they have expert assistance at every
              stage. Choosing Counselling India means investing in a brighter
              future with a well-planned and goal-oriented approach to
              education.{" "}
            </p>{" "}
            <p className="!text-[#616162] mt-3">
              In the coming years,<b> Counselling India</b> aims to expand its
              services, integrating advanced technology and global partnerships
              to provide better opportunities for students. Their expertise in
              career planning, university selection,admission guidance and
              financial aid will continue to be invaluable. As education
              evolves, Counselling India remains committed to guiding students
              toward a successful and fulfilling academic future.
            </p>
            <p className="!text-[#616162] mt-3">
              Choosing the right education consultant is a critical step in
              shaping a successful academic and professional career. The
              Counselling india <b> Top Education Consultant in Bangalore </b>
              provide invaluable support in career counseling, university
              selection, admission processes and more. Whether you aim to study
              in India or abroad, seeking guidance from a reputed consultant can
              significantly enhance your chances of success. By carefully
              evaluating the available options, students can make informed
              decisions and achieve their educational dreams with confidence.
            </p>
          </div>
        </Container>
      </section>

      {/* instruction section start  */}

      {/* top college details start*/}
      <section className="university_section  py-[50px] pb-[100px] lg:py-[50px]  md:py-[80px] md:pb-[120px]  sm:py-[50px] ">
        <div className="container">
          <div>
            <div className="mt-[30px] lg:mt-[30px] md:mb-16 sm:mb-36 xs:mb-10 md:mt-0">
              <h2 className=" title  text-center  text-[12px] text-[black] leading-none">
                <span>Top Education Consultants in </span>
                <span className="line_icon px-1">
                  <b>INDIA</b>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 150"
                    preserveAspectRatio="none"
                  >
                    <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                  </svg>
                </span>
              </h2>
            </div>
            <div className="mt-[50px] lg:mt-[30px]  md:mt-0">
              <div className="university_cards px-5 lg:px-5 md:!px-0 relative">
                <div className="row">
                  {topCollegeDetails.map((item, i) => {
                    return (
                      <div
                        className="col-lg-{6} col-md-6 col-sm-6 col-12"
                        key={i}
                      >
                        <div className="block-animation">
                          <div className="font-semibold flex justify-between">
                            <div className="text-[#1a1c45]">{item.name}</div>
                            <div>
                              {/* Modal Component Start */}
                              <div className="text-[#f78c1f] whitespace-pre   cursor-pointer flex items-center">
                                <Link
                                  to={item.link}
                                  className="text-[13px] pr-1"
                                >
                                  View
                                </Link>
                                <i className="fa fa-arrow-right"></i>
                              </div>
                              {/* Modal Component End */}
                            </div>
                          </div>
                          {/* <div className="">Address</div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* modal section end */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* enquiry form section start */}
      <m.section
        className="py-[160px] bg-cover 	bg-no-repeat overflow-hidden relative bg-center lg:py-[120px] md:py-[95px] xs:py-[80px] xxs:py-[50px]"
        style={{
          backgroundImage:
            "url(/assets/img/webp/MedicalCounsellingIndia/home/enquiry/enquiry.webp)",
        }}
        {...fadeIn}
      >
        <Container className="xs:px-0">
          <div className="flex justify-center mb-[100px] w-full">
            <p className="font-normal text-[20px] w-[70%] lg:w-[70%] md:w-[100%] text-center">
              Get Top Education Consultant in Bangalore. Kindly submit your data
              below and we shall connect you as soon as possible.
            </p>
          </div>
          <Row className="justify-end md:justify-center">
            <Col xl={7} lg={7} md={9} sm={11}>
              <div className="px-[3rem] py-[5rem] rounded-md shadow-[0_0_30px_rgb(0,0,0,0.08)] bg-white sm:p-20 xs:rounded-none xs:px-[3.5rem] xs:py-[6rem]">
                <span className="mb-[15px] font-medium text-center text-[#f78c1f] text-md font-serif uppercase block sm:mb-[10px]">
                  Submit your details here
                </span>
                <h5 className="w-[80%] mb-[40px] font-bold text-center	tracking-[-1px] text-black font-serif uppercase mx-auto xs:w-full">
                  YOU ARE JUST A CALL AWAY
                </h5>

                <form className="mb-[30px]" onSubmit={handleSubmit(onSubmit)}>
                  <m.div
                    className="row justify-center"
                    {...{ ...fadeIn, transition: { delay: 0.6 } }}
                  >
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="text"
                          name="name"
                          className="rounded-[5px] relative py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your name"
                          {...register("name", {
                            required: {
                              value: true,
                              message: "Name is required",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.name?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="text"
                          name="email"
                          className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your email address"
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required.",
                            },
                            pattern: {
                              value:
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                              message: "Enter valid email",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.email?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="number"
                          name="phone"
                          className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your phone number"
                          onKeyDown={handleKeyDown}
                          {...register("phone", {
                            required: {
                              value: true,
                              message: "Phone number is required",
                            },
                            pattern: {
                              value:
                                /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.phone?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="text"
                          name="address"
                          className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your address"
                          {...register("address", {
                            required: {
                              value: true,
                              message: "Address is required",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.address?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="text"
                          name="stream"
                          className="rounded-[5px] py-[13px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Subject"
                          {...register("stream", {
                            required: {
                              value: true,
                              message: "Stream is required",
                            },
                            value: "Subject",
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.stream?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className="mb-[33px]">
                        <textarea
                          type="text"
                          rows={3}
                          name="message"
                          className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your message"
                          {...register("message", {
                            required: {
                              value: true,
                              message: "Message is required",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-10px] translate-x-[10px]">
                          {errors.message?.message}
                        </div>
                      </div>
                    </Col>
                  </m.div>

                  <Buttons
                    type="submit"
                    className={`btn-fill text-sm leading-none font-medium tracking-[1px] !py-[17px] px-[32px] rounded-[4px] w-full uppercase mb-[5px] ${
                      formLoading ? "loading" : ""
                    }`}
                    themeColor="#f78c1f"
                    color="#fff"
                    size="lg"
                    title="Request a call schedule"
                  />
                </form>
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </m.section>
      {/* enquiry form section end */}
    </>
  );
}

export default Bangalore;
