import React, { useEffect, useRef, useState } from "react";

/** Libraries */
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

/** Components */
import MetaTags from "../../Components/MetaTags";
import ContactForm from "../ContactUs/ContactForm";

/** style */
import "../../../Assets/scss/MedicalCounsellingIndia/pages/home.scss";
import CommonContactButton from "../../Components/AnotherButton/CommonContactButton";

/** content data */
import { DirectAdmissionContent } from "./DirectAdmissionContent";

const DirectAdmission = () => {
  const [collegeData, setCollegeData] = useState();
  const collegeSectionRef = useRef(null);
  const location = useLocation();
  // const navigate = useNavigate();

  const { collegeName } = useParams();

  const exceptions = [
    "in",
    "on",
    "at",
    "by",
    "for",
    "and",
    "but",
    "or",
    "nor",
    "the",
    "a",
    "an",
  ];
  const collegeTitle = collegeName
    .split("-")
    .map((curElem) => {
      if (exceptions.includes(curElem)) return curElem;
      return curElem.charAt(0).toUpperCase() + curElem.slice(1);
    })
    .join(" ");

  useEffect(() => {
    // if (collegeName) {
    //   navigate(`/${collegeName}`, { replace: true });
    // }

    const filterCollegeData = DirectAdmissionContent.find((curElem) =>
      collegeTitle.includes(curElem.collegeName)
    );

    if (filterCollegeData !== undefined) {
      setCollegeData(filterCollegeData);

      console.log("collegeData: ", filterCollegeData);
    }
  }, [collegeTitle]);

  /** scroll to top */
  useEffect(() => {
    if (
      location.search.includes("scrollToCollege=true") &&
      collegeSectionRef.current
    ) {
      collegeSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.search]);

  return (
    <>
      <MetaTags
        title={`${collegeTitle} - Top Colleges, Eligibility & Application`}
        description={`${collegeTitle} Get details on eligibility, fees, entrance exams, and application process for MBA programs.`}
        keywords="MBA Admission 2025,MBA admission 2025, MBA eligibility 2025, MBA entrance exams 2025, MBA colleges 2025, MBA application 2025 Admission in Symbiosis Medical College for Women Direct admission in Symbiosis Medical College through Management/NRI Quota Admission in Symbiosis Medical College for Women Admission in Sree Balaji Medical College Hospital "
      />

      {/* Banner Section Start */}
      <section className="full-screen md:h-[900px] sm:h-[500px] mt-[80px] sm:mt-[120px]">
        <Swiper
          className="slider-nav-dark white-move swiper-pagination-03 swiper-pagination-light swiper-pagination-large h-full"
          modules={[Pagination, Autoplay]}
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          pagination={{ dynamicBullets: false, clickable: true }}
          initialSlide={0}
        >
          {collegeData !== undefined &&
            collegeData.sliderImg.reverse().map((img, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{
                    backgroundImage: `url(${img})`,
                  }}
                  className="bg-no-repeat	bg-cover overflow-hidden relative bg-top"
                >
                  {/* <div className="absolute h-full w-full top-0 left-0 bg-gradient-dark-slate-blue"></div> */}
                  <Container className="h-full">
                    <Row className="h-full">
                      <Col
                        xl={6}
                        lg={7}
                        sm={8}
                        xs={12}
                        className="h-full text-white font-serif justify-center flex-col relative flex"
                      >
                        {/* <h3 className=" font-semibold ">{item.title}</h3>
                      <h3 className="mb-[45px] font-semibold xs:mb-[25px]">
                        {item.subtitle}
                      </h3> */}
                        <div className="inline-block">
                          {/* <Buttons
                          href="https://1.envato.market/AL7Oj"
                          target="_blank"
                          className="btn-fancy text-xs tracking-[1px] font-serif font-medium rounded-none py-[12px] mr-[30px] uppercase md:mb-[15px] sm:mb-0"
                          themeColor={["#556fff", "#ff798e"]}
                          size="md"
                          color="#fff"
                          title="PURCHASE NOW"
                        /> */}
                          {/* <Buttons
                          to="/contact"
                          size="md"
                          className="!pt-0 px-0 pb-[2px] relative border-b-[2px] bg-transparent m-auto after:h-[2px] after:bg-white font-serif text-[13px] tracking-[0.5px] hover:text-white uppercase btn-link md:mb-[15px] mb-[15px]"
                          color="#fff"
                          title="CONTACT US"
                        /> */}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </section>
      {/* Banner Section End */}

      {/* about section start */}
      <section className="bg-lightgray py-[100px] pb-[100px] lg:py-[50px]  md:py-[80px] md:pb-[120px]  sm:py-[50px]">
        <Container>
          {/* How to get Admission */}
          <div className="">
            <h1 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-10">
              {`${collegeTitle} in 2025`}
            </h1>
            <div>
              <div className="!text-[#616162] mt-4  ">
                {collegeData !== undefined &&
                  collegeData.collegeData.map((data, index) => {
                    return (
                      <div className="mt-3" key={index}>
                        {data}
                      </div>
                    );
                  })}

                {collegeData !== undefined &&
                  collegeData.courseAvailable != null && (
                    <>
                      <div className="mt-5">
                        <h2 className="font-semibold text-[#616162] text-[18px] leading-none m-0 ">
                          {collegeData.courseAvailable.title}
                        </h2>

                        <ol className="!text-[#616162] mt-4 ml-10  md:ml-5 list-decimal flex flex-wrap">
                          {collegeData.courseAvailable.courseName.map(
                            (course, index) => {
                              return (
                                <li
                                  className="w-1/4 md:w-1/2 sm:w-full"
                                  key={index}
                                >
                                  {course}
                                </li>
                              );
                            }
                          )}
                        </ol>
                      </div>
                    </>
                  )}

                {collegeData !== undefined &&
                  collegeData.rewardsRecognition != null && (
                    <>
                      <div className="mt-[50px]">
                        <h2 className="font-semibold text-[#616162] text-[18px] leading-none m-0 ">
                          {collegeData.rewardsRecognition.title}
                        </h2>
                        {collegeData.rewardsRecognition.data.map(
                          (data, index) => {
                            return (
                              <div className="mt-3" key={index}>
                                {data}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}

                {collegeData !== undefined &&
                  collegeData.collegeAdvantage != null && (
                    <>
                      <div className="mt-5">
                        <h2 className="font-semibold text-[#616162] text-[18px] m-0 leading-10">
                          {collegeData.collegeAdvantage.title}
                        </h2>

                        <ol className="!text-[#616162] mt-4 ml-10  md:ml-5 list-decimal flex flex-wrap">
                          {collegeData.collegeAdvantage.keyPoints.map(
                            (points, index) => {
                              return (
                                <li className="w-full mb-1" key={index}>
                                  {points}
                                </li>
                              );
                            }
                          )}
                        </ol>
                      </div>
                    </>
                  )}

                {collegeData !== undefined &&
                  collegeData.admissionProcess != null && (
                    <>
                      <div className="mt-5">
                        <h2 className="font-semibold text-[#616162] text-[18px]  m-0 leading-10">
                          {collegeData.admissionProcess.title}
                        </h2>
                      </div>

                      {collegeData.admissionProcess.data.map((data, index) => {
                        return (
                          <div className="mt-3" key={index}>
                            {data}
                          </div>
                        );
                      })}
                    </>
                  )}
              </div>
            </div>
          </div>

          <div className="mt-5 ">
            <CommonContactButton />
          </div>

          {collegeData !== undefined && collegeData.whyChoose != null && (
            <>
              <div className="mt-[50px]">
                <h2 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-10">
                  {collegeData.whyChoose.title}
                </h2>
                <div>
                  {collegeData.whyChoose.data.map((data, index) => {
                    return (
                      <p className="!text-[#616162] mb-3" key={index}>
                        {data}
                      </p>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {/* <section className="bg-lightgray py-[50px] sm:pb-0">
            <div className="mb-[3.5%]">
              <h5 className="font-serif text-dark  font-medium">
                Hospital Update
              </h5>
            </div>
            <Row className="justify-center">
              <Col xs={12} sm={8} md={12}>
                <Row
                  className={`row-cols-1 row-cols-lg-3 row-cols-md-2 justify-center md:mb-[30px] gap-y-10 md:justify-center`}
                >
                  <m.div
                    className={`col icon-with-text-04 data_card`}
                    fadeIn
                    {...{
                      transition: {
                        delay: 0 * 0.2,
                      },
                    }}
                  >
                    <div className="rounded-md w-full flex items-center gap-[20px]">
                      <div className="feature-box-content">
                        <span className="font-medium title font-serif mb-0">
                          Date :
                        </span>
                      </div>
                      <div>03-04-2024</div>
                    </div>
                  </m.div>
                  <m.div
                    className={`col icon-with-text-04 data_card`}
                    fadeIn
                    {...{
                      transition: {
                        delay: 0 * 0.2,
                      },
                    }}
                  >
                    <div className="rounded-md w-full flex items-center gap-[20px]">
                      <div className="feature-box-content">
                        <span className="font-medium title font-serif mb-0">
                          OPD :
                        </span>
                      </div>
                      <div> 1077</div>
                    </div>
                  </m.div>
                  <m.div
                    className={`col icon-with-text-04 data_card`}
                    fadeIn
                    {...{
                      transition: {
                        delay: 0 * 0.2,
                      },
                    }}
                  >
                    <div className="rounded-md w-full flex items-center gap-[20px]">
                      <div className="feature-box-content">
                        <span className="font-medium title font-serif mb-0">
                          IPD :
                        </span>
                      </div>
                      <div> 457</div>
                    </div>
                  </m.div>
                </Row>
              </Col>
            </Row>
          </section> */}

          {collegeData !== undefined && collegeData.eligibility != null && (
            <>
              <div className=" mt-[50px] sm:mt-[30px]">
                <h2 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-10">
                  {collegeData.eligibility.title}
                </h2>
                <div>
                  <table className="!text-[#616162]  mt-[40px] table border-collapse border-3 border border-[#194297] ">
                    <tbody>
                      {collegeData.eligibility.keyPoints.map(
                        (points, index) => {
                          return (
                            <tr key={index}>
                              <td className="border border-slate-700">
                                {points}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}

          {collegeData !== undefined &&
            collegeData.requireDocuments != null && (
              <div className="!text-[#616162] mt-[50px]  ">
                <div className="mt-5">
                  <h2 className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4 leading-10">
                    {collegeData.requireDocuments.title}
                  </h2>

                  <ol className="!text-[#616162] mt-4 ml-10  md:ml-5 list-decimal flex flex-col">
                    {collegeData.requireDocuments.keyPoints.map(
                      (points, index) => {
                        return (
                          <li className="!text-[#616162] mb-3" key={index}>
                            {points}
                          </li>
                        );
                      }
                    )}
                  </ol>
                </div>
              </div>
            )}
        </Container>
      </section>
      {/* about section end */}

      <ContactForm />
    </>
  );
};

export default DirectAdmission;
